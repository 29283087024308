import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [{
    path: "/",
    redirect: "/index"
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/index"),//首页
  },
  {
    path: "/index2",
    name: "index2",
    component: () => import("@/views/index2"),//首页
  },
  {
    path: "/sign",
    name: "sign",
    component: () => import("@/views/sign"),//打卡
  },
  {
    path: "/form",
    name: "form",
    component: () => import("@/views/form"),//添加薪资
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login"),//登陆
  },
  {
    path: "/record",
    name: "record",
    component: () => import("@/views/record"),//打卡记录
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router